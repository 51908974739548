import React from 'react';
import PropTypes from 'prop-types';

import { FlatButton } from '../../../../components/button';

import styles from '../../styles/buttons.module.css';

const LABELS = {
  BACK: 'Назад',
};

const BackButton = ({ onMoveToDocuments }) => (
  <div className={ styles.back_button }>
    <FlatButton onClick={ onMoveToDocuments }>
      <i className='material-icons'>
        keyboard_arrow_left
      </i>
      { LABELS.BACK }
    </FlatButton>
  </div>
);

BackButton.propTypes = {
  onMoveToDocuments: PropTypes.func.isRequired,
};

export { BackButton };
