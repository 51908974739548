import React from 'react';
import PropTypes from 'prop-types';

import styles from '../../styles/buttons.module.css';

const LABELS = {
  DELETE: 'Удалить',
};

const DeleteButton = ({ onHandleClick }) => (
  <div>
    <a
      className={ styles.delete_button }
      onClick={ onHandleClick }
    >
      { LABELS.DELETE }
    </a>
  </div>
);

DeleteButton.propTypes = {
  onHandleClick: PropTypes.func.isRequired,
};

export { DeleteButton };
