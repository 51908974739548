import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { createRoot } from 'react-dom/client';

import { FlatButton } from '../../components/button';
import { FormNewTrip } from '../trip/components/formNewTrip';
import { renderNew } from '../trip/components/editPanel/editPanel';

import { prepareDataEdit, prepareDataCreateTrip, prepareUsavedItem } from '../../bi/utils/cart';

import { SERVICETYPE, SERVICETYPERU } from '../../bi/constants/serviceType';
import { LABELS_EDIT } from '../../bi/constants/cart';

import styles from '../trip/styles/trip.module.scss';

const LABELS = {
  NEW_TRIP: 'Создание новой поездки',
  DELETED_CART: 'Корзина была удалена пользователем',
};

const CartToTrip = ({
  appService,
  cartsAndNotesService,
  companyService,
  tripService,
  guidService,
  travelPolicyService,
  moveToCart,
  moveToTrip,
}) => {
  const [store, setStore] = useState({
    carts: [],
    versions: [],
    users: [],
    companies: [],
    analytics: [],
    userAnalytics: [],
    mandatoryProject: [],
    itemCart: null,
  });
  const [openSubMenu, setOpenSubMenu] = useState(false);

  const submenu = useRef();

  const cartId = appService.get().params.cartId;
  const accountId = appService.get().params.accountId;
  const user = appService.get().params.user;
  const companyId = appService.get().params.companyId;

  const {
    carts,
    versions,
    users,
    companies,
    projects,
    analytics,
    mandatoryProject,
    itemCart,
    loading,
    isLoad,
  } = store;

  const getDescriptions = (item) => {
    let data = null;

    if (item.Data) {
      data = JSON.parse(item.Data);
    } else {
      data = JSON.parse(item.JsonData);
    }

    switch (item.ServiceType) {
      case SERVICETYPE.HOTEL:
        return data.hotel.Name;

      default:
        return LABELS_EDIT.DEFAULT_DESCRIPTION;
    }
  };

  const updateState = (newStore) => {
    let versionsCart = [];

    const selectedCart = newStore.carts.find(({ Id }) => Number(cartId) === Id);
    const isCartFromRequest = !!newStore.versions.filter(({ Items }) => Items).length;

    if (isCartFromRequest) {
      versionsCart = newStore.versions.find(({ Id, CartId }) => {
        if (CartId) {
          return Number(cartId) === CartId;
        }

        return Number(cartId) === Id;
      });
    } else {
      versionsCart = {
        Items: newStore.versions,
      };
    }

    const companiesList = newStore.companies.map(({ CompanyId, CompanyName, ShortCompanyName }) => ({
      id: CompanyId,
      name: ShortCompanyName || `${CompanyName} (${CompanyId})`,
    }));
    const projectsEmployee = newStore.projects.length ? newStore.projects.map(({ Id, IsArchival, Name }) => ({
      id: Id,
      name: Name,
      isArchival: IsArchival,
    })) : [];
    const analyticsList = newStore.analytics.filter(({ ApplyToTrip }) => !ApplyToTrip);

    if (versionsCart && versionsCart.Items) {
      versionsCart = versionsCart.Items.map(item => ({
        ...item,
        Description: getDescriptions(item),
        Status: 4,
        IsNotCorrect: !!item.Data,
      }));
    }

    setStore({
      ...newStore,
      carts: selectedCart,
      versions: versionsCart || [],
      companies: companiesList,
      projects: projectsEmployee,
      analytics: analyticsList,
    });
  };

  const unsubscribe = () => cartsAndNotesService.subscribe(updateState);

  const handleTouchOutside = (event) => {
    if (submenu.current && !submenu.current.contains(event.target)) {
      setOpenSubMenu(false);
    }
  };

  useEffect(() => {
    unsubscribe();

    cartsAndNotesService.loadCartsAndNotes(user, accountId, companyId);
    companyService.setId(companyId);
    companyService.loadProfile(accountId);

    document.addEventListener('click', handleTouchOutside);

    return () => {
      document.removeEventListener('click', handleTouchOutside);
    };
  }, []);

  const handleCancelNew = () => cartsAndNotesService.setItemCart(null);

  const handleMoveToCart = () => {
    moveToCart();
    handleCancelNew();
  };

  const handleEditItem = async (item) => {
    const tripToEdit = versions.find(version => version.Id === item.Id);
    const newVersions = versions.filter((version, index) => {
      if (version.Id === item.Id) {
        tripToEdit.indexEdited = index;
      }

      return version.Id !== item.Id;
    });

    cartsAndNotesService.changeCarts(newVersions);

    let departments = [];

    if (item.Employees && item.Employees.length) {
      departments = await cartsAndNotesService.getDepartments(item.Employees[0].Employee.Id, item.CompanyId);

      cartsAndNotesService.setItemCart(prepareDataEdit(item, departments, true));
    } else {
      departments = await cartsAndNotesService.getDepartments(item.EmployeesList[0].Id, item.CompanyId);

      cartsAndNotesService.setItemCart(prepareUsavedItem(item, tripToEdit, departments));
    }
  };

  const handleConfirmNew = async (item) => {
    handleCancelNew();

    const modifiedItem = { ...item, Id: Number(cartId) };

    modifiedItem.OrderTripId = 0;

    const newState = versions;
    const itemIndex = newState.findIndex(version => version.Id === modifiedItem.Id);

    if (item.indexEdited !== null) {
      newState.splice(modifiedItem.indexEdited, 0, modifiedItem);

      return cartsAndNotesService.changeCarts(newState);
    }

    if (itemIndex >= 0) {
      newState.versions[itemIndex] = modifiedItem;
    } else {
      newState.versions.push(modifiedItem);
    }

    return cartsAndNotesService.changeCarts(newState);
  };

  const handleNewItem = (type) => {
    const item = tripService.addNewItem(type);

    setOpenSubMenu(false);
    cartsAndNotesService.setItemCart(item);
  };

  const handleCreate = async () => {
    const data = {
      UserId: user,
      Name: carts.Name,
      Users: users.map(({ Email }) => Email),
      Versions: versions.map(item => prepareDataCreateTrip(item)),
      UserAnalytics: carts.UserAnalytics,
      BoCartId: cartId,
    };

    const tripId = await cartsAndNotesService.createTripFromCart(companyId, data, '');

    if (tripId) {
      moveToTrip(tripId);
    }
  };

  const renderError = () => isLoad && !carts ? (
    <h3>{ LABELS.DELETED_CART }</h3>
  ) : null;

  const setTripAnalytics = (analyticsValues = []) =>
    cartsAndNotesService.setAnalytics(analyticsValues);

  const disabledCreateTrip = (versions && versions.some(item => item.IsNotCorrect)) || loading;

  const renderOrderSubMenu = () => (
    <div className={ `${styles['sub-menu']} ${openSubMenu && styles.open}` }>
      <div className={ styles['menu-item'] } onClick={ () => handleNewItem(SERVICETYPE.HOTEL) }>
        { SERVICETYPERU[SERVICETYPE.HOTEL] }
      </div>
    </div>
  );

  return (
    <div className={ styles.wrap }>
      <div className={ styles['button-trip__left'] }>
        <FlatButton onClick={ handleMoveToCart }>
          <i className='material-icons'>keyboard_backspace</i> { LABELS_EDIT.TO_CART }
        </FlatButton>
      </div>
      { renderError() }
      <FormNewTrip
        users={ users }
        versions={ versions || [] }
        submenu={ submenu }
        label={ LABELS.NEW_TRIP }
        isCreateCart
        isCartToTrip
        onEditItem={ handleEditItem }
        onConfirmNew={ handleCreate }
        renderOrderSubMenu={ renderOrderSubMenu }
        disabledCreateTrip={ disabledCreateTrip }
        loadingCreateTrip={ loading }
      />
      <div
        className={ styles.row }
      >
        <div className={ styles.col }>
          { renderNew({
            companies,
            mandatoryProject,
            projectsForNewTrip: projects,
            tripService,
            travelPolicyService,
            accountId,
            item: itemCart,
            onConfirm: handleConfirmNew,
            onCancel: handleCancelNew,
            analytics,
            tripAnalyticsValueIds: [],
            setTripAnalytics,
            addTrip: () => {},
            guidService,
          }) }
        </div>
      </div>
    </div>
  );
};

CartToTrip.propTypes = {
  appService: PropTypes.object.isRequired,
  cartsAndNotesService: PropTypes.object.isRequired,
  companyService: PropTypes.object.isRequired,
  tripService: PropTypes.object.isRequired,
  guidService: PropTypes.object.isRequired,
  travelPolicyService: PropTypes.object.isRequired,
  moveToCart: PropTypes.func.isRequired,
  moveToTrip: PropTypes.func.isRequired,
};

const renderComponents = (box, opts) => {
  const root = createRoot(box);

  const page = (
    <CartToTrip
      appService={ opts.appService }
      cartsAndNotesService={ opts.cartsAndNotesService }
      companyService={ opts.companyService }
      tripService={ opts.tripService }
      guidService={ opts.guidService }
      travelPolicyService={ opts.travelPolicyService }
      moveToCart={ opts.moveToCart }
      moveToTrip={ opts.moveToTrip }
    />
  );

  root.render(page);

  return root;
};

export default renderComponents;
