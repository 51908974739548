import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'sw-ui';

import COMPONENTS from '../../../../bi/constants/components';
import { TYPE_PATH } from '../../../../bi/constants/documentsConstructor';

import styles from '../../styles/buttons.module.css';

const AddButton = ({
  buttonText,
  tooltipText,
  disabled,
  onMoveToNewDocument,
}) => {
  const handleMoveToNewDocument = () => onMoveToNewDocument(TYPE_PATH.NEW);

  const className = disabled ? `${styles.add_button} ${styles.add_button_disabled}` : styles.add_button;

  const renderTooltip = () => {
    if (!disabled) {
      return null;
    }

    return (
      <Tooltip
        position={ COMPONENTS.TOOLTIP.POSITION.LEFT }
      >
        { tooltipText }
      </Tooltip>
    );
  };

  return (
    <div className={ 'sw-tooltip-wrapper' }>
      <button
        className={ className }
        disabled={ disabled }
        onClick={ handleMoveToNewDocument }
      >
        <i className='material-icons'>add</i>
        { buttonText }
      </button>
      { renderTooltip() }
    </div>
  );
};

AddButton.propTypes = {
  buttonText: PropTypes.string.isRequired,
  tooltipText: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onMoveToNewDocument: PropTypes.func.isRequired,
};

export { AddButton };
