import React from 'react';
import PropTypes from 'prop-types';

import AjaxButton from '../../../../components/ajaxButton';

import styles from '../../styles/buttons.module.css';

const LABELS = {
  SAVE: 'Сохранить',
  ERROR: 'Добавьте хотя бы одну услугу',
};

const SaveButton = ({
  onHandleSaveDocument,
  disabled,
}) => {
  const error = disabled ? LABELS.ERROR : null;

  return (
    <div>
      <AjaxButton
        onClick={ onHandleSaveDocument }
        label={ LABELS.SAVE }
        className={ styles.save_button }
        theme=''
        disabled={ disabled }
      />
      <div className={ styles.error }>
        { error }
      </div>
    </div>
  );
};

SaveButton.propTypes = {
  onHandleSaveDocument: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export { SaveButton };
