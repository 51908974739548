import React from 'react';
import PropTypes from 'prop-types';
import { createRoot } from 'react-dom/client';

import { BackButton } from './components/Buttons/BackButton';
import { SettingOPZ } from './components/SettingOPZ/SettingOPZ';
import { Navigation } from './components/Navigation';

import styles from './styles/index.module.css';

const DocumentsConstructor = ({
  documentsConstructorService,
  moveToDocuments,
  toAnotherTab,
  moveToDocument,
  params,
}) => (
  <div className={ styles.wrap }>
    <BackButton
      onMoveToDocuments={ moveToDocuments }
    />
    <SettingOPZ
      documentsConstructorService={ documentsConstructorService }
    />
    <Navigation
      onToAnotherTab={ toAnotherTab }
      documentsConstructorService={ documentsConstructorService }
      onMoveToNewDocument={ moveToDocument }
      onMoveToEditDocument={ moveToDocument }
      params={ params }
    />
  </div>
);

DocumentsConstructor.propTypes = {
  documentsConstructorService: PropTypes.object.isRequired,
  moveToDocuments: PropTypes.func.isRequired,
  toAnotherTab: PropTypes.func.isRequired,
  moveToDocument: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
};

const renderComponents = (box, opts) => {
  const root = createRoot(box);

  root.render(
    <DocumentsConstructor
      { ...opts }
    />
  );

  return root;
};

export default renderComponents;
