import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { RadioButton } from 'sw-ui';

import styles from '../../../styles/settings.module.css';

const LABELS = {
  DIVIDE_ACTS: 'Разделить акты',
  WITHOUT_DIVIDE: 'Без разделения',
  DIVIDE_CC: 'Разделить по ЦЗ',
  DIVIDE_1C: 'Разделить по заявкам 1С',
  DIVIDE_EMPLOYEES: 'Разделить по сотрудникам',
  DIVIDE_TRIPS: 'Разделить по поездкам',
};

const ActsSetting = ({ documentsConstructorService }) => {
  const initState = documentsConstructorService.get().Acts.DocumentDivideTypes;
  const [val, setVal] = useState(initState);

  const update = ({ Acts }) => setVal(Acts.DocumentDivideTypes);

  useEffect(() => {
    const unSubscribe = documentsConstructorService.subscribe(update);

    return () => {
      if (unSubscribe) unSubscribe();
    };
  }, [documentsConstructorService]);

  const handleChange = (value) => {
    setVal(value);
    documentsConstructorService.setDocumentDivideTypes(value);
  };

  return (
    <div className={ styles.content }>
      <div className={ styles.title }>
        { LABELS.DIVIDE_ACTS }
      </div>
      <div className={ styles.radio }>
        <RadioButton
          onChange={ () => handleChange(LABELS.WITHOUT_DIVIDE) }
          checked={ val === LABELS.WITHOUT_DIVIDE }
          className={ styles.radio_width }
        >
          { LABELS.WITHOUT_DIVIDE }
        </RadioButton>
        <RadioButton
          onChange={ () => handleChange(LABELS.DIVIDE_CC) }
          checked={ val === LABELS.DIVIDE_CC }
          className={ styles.radio_width }
        >
          { LABELS.DIVIDE_CC }
        </RadioButton>
        <RadioButton
          onChange={ () => handleChange(LABELS.DIVIDE_1C) }
          checked={ val === LABELS.DIVIDE_1C }
          className={ styles.radio_width }
        >
          { LABELS.DIVIDE_1C }
        </RadioButton>
        <RadioButton
          onChange={ () => handleChange(LABELS.DIVIDE_EMPLOYEES) }
          checked={ val === LABELS.DIVIDE_EMPLOYEES }
          className={ styles.radio_width }
        >
          { LABELS.DIVIDE_EMPLOYEES }
        </RadioButton>
        <RadioButton
          onChange={ () => handleChange(LABELS.DIVIDE_TRIPS) }
          checked={ val === LABELS.DIVIDE_TRIPS }
          className={ styles.radio_width }
        >
          { LABELS.DIVIDE_TRIPS }
        </RadioButton>
      </div>
    </div>
  );
};

ActsSetting.propTypes = {
  documentsConstructorService: PropTypes.object.isRequired,
};

export { ActsSetting };
