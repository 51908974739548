import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MultiSelect } from 'sw-ui';

import { SettingServices } from './SettingServices';

import {
  combineOperations,
  updateServices,
  updateNameContents,
} from '../../../../bi/utils/documentsConstructor';

import { DOCUMENTS } from '../../../../bi/constants/documentsConstructor';

import styles from '../../styles/index.module.css';

const LABELS = {
  EDIT: 'Редактирование',
  NEW_ACT: 'Создание акта',
  NEW_INVOICE: 'Создание счет-фактуры',
  ACT_TYPES_SERVICES: 'Виды услуг в акте',
  INVOICE_TYPES_SERVICES: 'Виды услуг в счет-фактуре',
};

const DocumentSetup = ({
  settings,
  notAddedOperations,
  onChangeSettings,
  onChangeNotAddedOperations,
  tabId,
}) => {
  const { ServiceOperations, Name } = settings;
  const initState = ServiceOperations.flatMap(({ ServiceOperationNames }) => ServiceOperationNames.map(({ name }) => name));

  const [selectedServices, setSelectedServices] = useState(initState);

  const actTitle = Name ? `${LABELS.EDIT} ${Name}` : LABELS.NEW_ACT;
  const invoiceTitle = Name ? `${LABELS.EDIT} ${Name}` : LABELS.NEW_INVOICE;
  const subTitle = tabId === DOCUMENTS.ACTS ? LABELS.ACT_TYPES_SERVICES : LABELS.INVOICE_TYPES_SERVICES;

  const updateState = (values, updatedOperations = ServiceOperations) => {
    const {
      updatedServiceOperations,
      updatedNotAddedOperations,
    } = updateServices(values, notAddedOperations, updatedOperations, tabId);

    onChangeSettings({ ...settings, ServiceOperations: updatedServiceOperations });
    onChangeNotAddedOperations(updatedNotAddedOperations);
    setSelectedServices(values);
  };

  const handleChangeServices = selectedValues => updateState(selectedValues);

  const handleRemoveServices = (name) => {
    const updatedServiceOperations = updateNameContents(name, ServiceOperations);

    const filterValues = selectedServices.filter(item => item !== name);
    updateState(filterValues, updatedServiceOperations);
  };

  const handleSettingServiceNames = (serviceName, droppedItems) => {
    const updatedServiceOperations = updateNameContents(serviceName, ServiceOperations, droppedItems);

    onChangeSettings({ ...settings, ServiceOperations: updatedServiceOperations });
  };

  const renderMultiSelect = () => {
    const operations = combineOperations(ServiceOperations, notAddedOperations);

    return operations.map(({ ServiceType, ServiceOperationNames }) => {
      const items = ServiceOperationNames.map(({ name }) => ({ label: name, value: name }));

      return (
        <div className={ styles.multiselect } key={ ServiceType }>
          <MultiSelect
            selectAll
            items={ items }
            values={ selectedServices }
            label={ ServiceType }
            onChange={ handleChangeServices }
          />
        </div>
      );
    });
  };

  const renderSettingServices = () => {
    if (!selectedServices.length || tabId === DOCUMENTS.INVOICES) {
      return null;
    }

    return (
      <SettingServices
        selectedServices={ selectedServices }
        onHandleRemoveServices={ handleRemoveServices }
        onHandleSettingServiceNames={ handleSettingServiceNames }
        serviceOperations={ ServiceOperations }
      />
    );
  };

  const renderTitle = () => {
    if (DOCUMENTS.ACTS === tabId) {
      return actTitle;
    }

    if (DOCUMENTS.INVOICES === tabId) {
      return invoiceTitle;
    }

    return null;
  };

  return (
    <div className={ styles.content_wrap }>
      <div className={ styles.document_setup_wrap }>
        <div className={ styles.content }>
          <div className={ styles.title }>
            { renderTitle() }
          </div>
          <div className={ styles.subtitle }>
            { subTitle }
          </div>
          <div className={ styles.multiselect_wrap }>
            { renderMultiSelect() }
          </div>
        </div>
      </div>
      { renderSettingServices() }
    </div>
  );
};

DocumentSetup.propTypes = {
  settings: PropTypes.object.isRequired,
  notAddedOperations: PropTypes.array.isRequired,
  onChangeSettings: PropTypes.func.isRequired,
  onChangeNotAddedOperations: PropTypes.func.isRequired,
  tabId: PropTypes.string.isRequired,
};

export { DocumentSetup };
